import React, { ComponentType } from 'react'
import {
    List,
    Datagrid,
    TextField,
    UrlField,
    Button,
    FilterProps,
    useListContext,
    Filter,
    SelectInput,
    useRecordContext,
    useDataProvider,
    TopToolbar,
    useGetOne,
    ExportButton,
    Pagination,
} from 'react-admin'
import { Card, CardContent, LinearProgress, Typography } from '@mui/material'
import {
    PointsApplication,
    PointsApplicationState,
} from '../../resourceDescriptions/points-application-list'
import { resourcesIds } from '../../resources'
import { useQueryParams } from '../../hooks/useQueryParams'
import { DropEvent } from '../../types'

export const PointsApplicationList: ComponentType = () => (
    <List
        disableSyncWithLocation
        empty={false}
        title="Points Application List"
        filters={<Filters />}
        filterDefaultValues={{
            state: PointsApplicationState.PENDING,
        }}
        actions={<PointsApplicationListActions />}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} />}
    >
        <Datagrid bulkActionButtons={false}>
            <UrlField label="Channel URL" source="channelUrl" target="_blank" />
            <TextField label="Applied" source="created" />
            <TextField
                label="Last drop date"
                source="lastDropParticipationDate"
            />
            <TextField
                label="Last reservation date"
                source="lastReservationDate"
            />
            <TextField label="CCV" source="averageViewers" />
            <TextField label="Drops #" source="uniqueDropsNumber" />
            <TextField label="Estimated points" source="estimatedPoints" />
            <UrlField
                label="Sullygnome"
                source="sullygnomeLink"
                target="_blank"
            />
            <TextField label="Language" source="language" />
            <TextField label="Comment" source="comment" />
            <TextField label="Total reservations" source="totalReservations" />
            <TextField
                label="Total realized reservations"
                source="totalRealizedReservations"
            />
            <TextField
                label="Average drop stream time (hours)"
                source="averageDropStreamTime"
            />
            <StateButtons />
        </Datagrid>
    </List>
)

const StateButtons = () => {
    const record = useRecordContext<PointsApplication>()

    if (record.state !== 'PENDING') {
        return null
    }

    return (
        <>
            <ApproveButton />
            <RejectButton />
        </>
    )
}

const ApproveButton = () => {
    const { setApplicationState } = useSetApplicationState(
        PointsApplicationState.APPROVED
    )
    return <Button onClick={setApplicationState} label="Approve" />
}

const RejectButton = () => {
    const { setApplicationState } = useSetApplicationState(
        PointsApplicationState.REJECTED
    )
    return <Button onClick={setApplicationState} label="Reject" />
}

const Filters = (props: Omit<FilterProps, 'children'>) => {
    return (
        <Filter {...props}>
            <SelectInput
                choices={[
                    { id: PointsApplicationState.APPROVED, name: 'Approved' },
                    { id: PointsApplicationState.REJECTED, name: 'Rejected' },
                ]}
                emptyValue={PointsApplicationState.PENDING}
                emptyText="Pending"
                label="Status"
                key="state"
                source="state"
                alwaysOn
            />
        </Filter>
    )
}

const useSetApplicationState = (state: PointsApplicationState) => {
    const dataProvider = useDataProvider()
    const list = useListContext()
    const record = useRecordContext<PointsApplication>()
    const { invalidateDrop } = usePointsCounter()

    const setApplicationState = async () => {
        const data = {
            platform: record.platform,
            platformId: record.platformId,
            dropId: record.dropId,
        }

        await dataProvider.update(resourcesIds.POINTS_APPLICATION_LIST, {
            id: `${record.platform}|${record.platformId}`,
            data: {
                ...data,
                state,
            },
            previousData: {
                ...data,
                state: record.state,
            },
        })

        await Promise.all([invalidateDrop(), list.refetch()])
    }

    return {
        setApplicationState,
    }
}

const PointsApplicationListActions = () => {
    const { isData, pointsLeft, pointsLimit, percentage } = usePointsCounter()

    if (!isData) {
        return null
    }

    return (
        <TopToolbar>
            <ExportButton />
            <Card>
                <CardContent>
                    <TextField source="name" />
                    <Typography variant="h6">Points left</Typography>
                    <Typography variant="body1">
                        {pointsLeft} points left out of {pointsLimit}
                    </Typography>
                    <LinearProgress variant="determinate" value={percentage} />
                </CardContent>
            </Card>
        </TopToolbar>
    )
}

const usePointsCounter = () => {
    const dropId = useQueryParams('value') || ''
    const { data, refetch } = useGetOne<DropEvent>(
        resourcesIds.CAMPAIGNS_RESOURCE_ID,
        {
            id: dropId,
        }
    )

    const invalidateDrop = refetch

    if (!data) {
        return {
            isData: false,
            pointsLeft: 0,
            pointsLimit: 0,
            percentage: 0,
            invalidateDrop,
        }
    }

    const { pointsLimit, pointsReserved, pointsAwarded } = data

    if (
        typeof pointsAwarded !== 'number' ||
        typeof pointsReserved !== 'number' ||
        typeof pointsLimit !== 'number'
    ) {
        return {
            isData: false,
            pointsLeft: 0,
            pointsLimit: 0,
            percentage: 0,
            invalidateDrop,
        }
    }

    const pointsLeft = pointsLimit - pointsAwarded - pointsReserved
    const percentage = (pointsLeft / pointsLimit) * 100

    return {
        isData: true,
        pointsLeft,
        pointsLimit,
        percentage,
        invalidateDrop,
    }
}
